import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header2 from './../sections/header2';
import Banner3 from './../sections/banner3';
import ClientBrandSection from './../sections/client-brand-section'
import Feature3 from './../sections/feature3';
// import ScreenShot3 from './../sections/screenshot3';
// import GetApp from './../sections/get-app-section';
import PricingSection from './../sections/pricing-section';
// import TestimonialSection2 from './../sections/testimonial-section2';
// import BlogSection from '../sections/blog-section';
import FaqSection from './../sections/faq-section';
import GetReady from './../sections/get-ready-section'
import Footer2 from '../sections/footer-section2';

class Home3 extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown'])
      } else {
        document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown'])
      }
    });
    return (
      <Fragment>
        <div className="App">
          <Header2 headerStyle={'header header-style-2 home3'}></Header2>
          <Banner3 bannerStyle={'banner-section bg-ash-color2 style3'}></Banner3>
          <ClientBrandSection clientSectionStyle={'client-logo-section style2 bg-ash-color2'}></ClientBrandSection>
          <Feature3 featureStyle={'feature-section bg-ash-color3 pt--60 pb--5 pt_lg--100 pb_lg--100'}></Feature3>
          <PricingSection pricingStyle={'pricing-section pt--50 pb--50 pt_lg--100 style3'}></PricingSection>
          <FaqSection faqStyle={'axsis-faq-section style2 bg-ash-color3 pt--50 pb--50 pt_lg--90 pb_lg--100'}></FaqSection>
          <GetReady></GetReady>
          <Footer2 footerStyle={'footer home3'}></Footer2>
        </div>
      </Fragment>
    );
  }
}

export default Home3;
